import {
  toast,
  type ToastOptions,
  type ToastPosition,
  type ToastType,
} from 'vue3-toastify'

export const useToast = () => {
  const notify = ({
    text,
    type,
    autoClose = 3000,
    position = 'top-right',
  }: {
    text: string
    type: ToastType
    autoClose?: number
    position?: ToastPosition
  }) => {
    toast(text, {
      autoClose,
      position,
      type,
    } as ToastOptions)
  }
  return {
    notify,
  }
}
